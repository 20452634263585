import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/header';
import LocationList from '../components/locationList';

const Routes = ({ pageContext, data, location }) => {
  const { intro } = pageContext;
  const { routes } = data;

  const calculatedRoutes = routes.edges.map(r => {
    return {
      extra: `Distancia: ${r.node.fields.length.toFixed(1)} m., ${
        r.node.frontmatter.steps.length
      } paradas`,
      ...r
    };
  });

  return (
    <article className="page routes">
      <Header title="Rutas" back="/" intro={intro} path={location.pathname} />

      <LocationList locations={calculatedRoutes} showExtra={true} />
    </article>
  );
};

export default Routes;

export const query = graphql`
  query {
    routes: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/rutas/" } }
    ) {
      edges {
        node {
          fields {
            slug
            length
          }
          frontmatter {
            title
            path
            type
            steps {
              point
            }
          }
        }
      }
    }
  }
`;
